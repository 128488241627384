import React, {Fragment} from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiButton
} from '@elastic/eui'

function TOCList() {

  return (
    <Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                  <StaticImage
                    src='../../../../../images/Software/iOS/InstarVision/iPad/Qrcode_iOS-AppStore_iPad.webp'
                    alt="InstarVision for iPhone INSTAR IP Camera"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
              }
              title="Apple Store Link"
              description="You can use a QR Reader to install our App. Or just click the image to be directed to the Apple Store."
              footer= {
                <Fragment>
                    <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem>
                            <EuiButton href="https://apps.apple.com/us/app/instarvision-for-ipad/id767539403" target="_blank" fill size="s">
                              App Store
                            </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem>
                            <EuiButton href="https://itunes.apple.com/us/app/qr-code-reader-by-scan/id698925807?mt=8" target="_blank" fill size="s">
                                QR Reader
                            </EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </Fragment>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>
    </Fragment>
  );
}

export default TOCList